@import '~@watersport/ui-react/dist/esm/index.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

.custom-oval-wrapper svg circle {
  stroke-opacity: 1 !important;
}

.range-slider {
  background-color: #19a5bd;
}

.range-slider-0 {
  background-color: #e5e7eb;
}

.range-slider-2 {
  background-color: #e5e7eb;
}
